import React from "react"
import { Link } from "react-router-dom";
import "./Button.css"

function Button( {
    children,
    className,
    id,
    type,
    to,
    ...props
    } ) {

    const buttonProps = { 
        ...props,
        id: id,
        className: `button${ className ? " " + className : "" }`
    }
    const ThisButton = to ?
        (<Link to={to} {...buttonProps}>{children}</Link>) :
        (<button type={type} {...buttonProps}>{children}</button>);

    return ThisButton
}

Button.defaultProps = {
    children: null,
    className: "",
    id: null,
    type: null,
    to: null
}

export default Button;