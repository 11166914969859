import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { LoaderButton } from "../components/button";
import Heading from "../components/heading/Heading";
import LabeledInput from "../components/labeledInput/LabeledInput";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { useToast } from "../components/toast"
import "./ChangePassword.css";

export default function ChangePassword() {
    const toast = useToast();
    const history = useHistory();
    const [fields, handleFieldChange] = useFormFields({
        password: "",
        oldPassword: "",
        confirmPassword: "",
    });
    const [isChanging, setIsChanging] = useState(false);

    function validateForm() {
        return (
            fields.oldPassword.length > 0 &&
            fields.password.length > 0 &&
            fields.password === fields.confirmPassword &&
            fields.oldPassword !== fields.password
        );
    }

    async function handleChangeClick(event) {
        event.preventDefault();
        setIsChanging( true );

        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(
                currentUser,
                fields.oldPassword,
                fields.password
            );
            toast.add( "New password saved" );
            setIsChanging( false );
            history.push( "/my-account" );
        } catch ( error ) {
            onError( error );
            setIsChanging( false );
        }
    }

    return (
        <div className="ChangePassword">
            <Heading level="h1" colour="black" bold>Change your password</Heading>
            <form onSubmit={handleChangeClick}>
                <LabeledInput 
                    labelText="Old Password"
                    onChange={ handleFieldChange }
                    value={ fields.oldPassword }
                    name={ "oldPassword" }
                    id="oldPassword"
                    type="password"
                />
                <LabeledInput 
                    labelText="Password"
                    onChange={ handleFieldChange }
                    value={ fields.password }
                    id="password"
                    name={ "password" }
                    type="password"
                />
                <LabeledInput 
                    labelText="Confirm Password"
                    onChange={ handleFieldChange }
                    id="confirmPassword"
                    value={ fields.confirmPassword }
                    name={ "confirmPassword" }
                    type="password"
                />
                <LoaderButton
                    block
                    type="submit"
                    disabled={ ! validateForm() }
                    isLoading={isChanging}
                >
                    Change Password
        </LoaderButton>
            </form>
        </div>
    );
}