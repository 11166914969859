import React from "react";
import "./Footer.css";
import Accordion from "../accordion/Accordion"
import axa from "../images/axa.png";
import nike from "../images/nike.png";
import standardChartered from "../images/standard-chartered-logo.png";
import expedia from "../images/expedia-logo.svg";

function Footer() {
    const baseProps = {
        target: "_blank",
        rel: "noreferrer noopener",
    }

    const tandc = "https://bookings.liverpoolfc.com/soccerschools/booking/toc.htm";
    const privacy = "https://www.liverpoolfc.com/legal/privacy-policy";

	return (
        <div className="footer">
            <div className="hr"/>
            <div className="footerlogos">
                <a href="https://www.sc.com/en/"><img className="footerlogos" alt="Partnered With Standard Chartered" src={standardChartered} /></a>
                <a href="https://www.nike.com/"><img className="footerlogos" alt="Partnered With Nike" src={nike} /></a>
                <a href="https://www.axa.co.uk"><img className="footerlogos" alt="Partnered With AXA" src={axa} /></a>
                <a href="https://www.expedia.co.uk/"><img className="footerlogos" alt="Partnered With Expedia" src={expedia} /></a>
            </div>
            <div className="hr"/>
            <Accordion title="Company Info" titleColour="white" titleLevel="p">
                <a href={tandc} {...baseProps}>Terms &amp; Conditions</a><br/>
                <a href={privacy} {...baseProps}>Privacy Policy</a><br/>
            </Accordion>
            <div className="hr"/>
            <p className="colophon">&copy; Liverpoolfc.com 2020, All Rights Reserved</p>
        </div>
	);
}

export default Footer;
