import React, { useState, useMemo } from 'react';
import { v4 } from "uuid";
import ToastContext from './ToastContext';
import Toast from './Toast';

function withToastProvider(Component) {
  function WithToastProvider(props) {
    const [toasts, setToasts] = useState([]);

    const add = content => {
      const id = v4();
      setToasts([...toasts, { id, content }]);
    };
    const remove = id => setToasts(toasts.filter(t => t.id !== id));
    const providerValue = useMemo(() => { return { add, remove } }, [toasts]);

    return (
      <ToastContext.Provider value={ providerValue }>
        <div className="toastsProviderWrapper">
          <Component {...props} />
          <div className="toasts__wrapper">
            { toasts.map(t => (
                <Toast key={t.id} remove={() => remove(t.id)}>
                  { t.content }
                </Toast>
            )) }
          </div>
        </div>
      </ToastContext.Provider>
    );
  }

  return WithToastProvider;
}

export default withToastProvider;