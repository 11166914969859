import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import standardCharteredLogo from "../images/standard-chartered-logo.png";
import { ReactComponent as Account } from "../images/AccountIcon.svg";
import HeaderNavButton from "../headerNavButton/HeaderNavButton";
import HeaderNavFold from "../headerNavFold/HeaderNavFold";
import NavBar from "../navBar/NavBar";
import "./Header.css";

function Header( { backLinkActive } ) {

	const [ headerOpen, setHeaderOpen ] = useState( false );
	const [ navBarOpen, setNavBarOpen ] = useState( false );
	const isActive = backLinkActive;

	useEffect( ()=> {
		if(isActive)
			setHeaderOpen(false)
	}, [isActive, setHeaderOpen])

	return (
		<Fragment>
			<div className={`masthead ${ isActive ? "hidden" : ""}`}>
				<div id="mastleft" className="masthead__left">
					<HeaderNavButton onClick={ e => setHeaderOpen( !headerOpen ) } isOpen={ headerOpen } />
				</div>
				<div id="mastright" className="masthead__right">
						<a href="https://www.sc.com"><img src={standardCharteredLogo} alt="Standard Chartered" className="sc"/></a>
						<Link to="/my-account"><Account className="icon"/></Link>
				</div>
			</div>
			<HeaderNavFold
				isOpen={ headerOpen }
			/>
			<NavBar
				isOpen={ navBarOpen }
				isActive={ isActive }
				onClick={ e => setNavBarOpen( !navBarOpen ) }
			/>
		</Fragment>
	);
}

export default Header;
