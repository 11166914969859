import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axios from "axios";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import { useToast } from "../components/toast";
import BillingForm from "../components/BillingForm";
import { config } from "../config";
import "./Payment.css";

export default function Payment() {
    const toast = useToast();

    const [stripe, setStripe] = useState(null);

    useEffect(() => {
        // Create the Stripe object yourself...
        const stripePromise = loadStripe(config().stripe.publicKey);

        setStripe(stripePromise);
    }, []);

    const { setOnloadTrigger } = useAppContext();

    const history = useHistory();

    // TODO: Ignore promo code and inform user that promo is ignored if they didn't press apply
    async function handleFormSubmit(chargeObject, clearPayNowButton) {
        try {
            const { data } = await axios.post(`${config().stripe.apiUrl}/chargeformembership`,
                chargeObject);

            if (data.requiresAuthentication) {
                const service = await stripe;
                const confirmedData = await service.confirmCardPayment(data.clientSecret);

                data.isSuccessful = confirmedData && confirmedData.paymentIntent && confirmedData.paymentIntent.status === 'succeeded'
            }

            if (data.isSuccessful) {
                history.push("/thank-you");

                toast.add("Thank you. Welcome to eAcademy.");

                setOnloadTrigger(true);
            }
            else {
                toast.add("Subscription failed");
            }
        } catch (e) {
            onError(e);

            alert("Subscription failed");
        }

        clearPayNowButton();
    }

    return (
        <div className="PaymentPage">
            <Elements stripe={stripe}>
                <BillingForm
                    onSubmit={handleFormSubmit}
                />
            </Elements>
        </div>
    );
}
