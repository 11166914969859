import React from "react";
import "./StarRating.css"
import { ReactComponent as Star } from "../images/star.svg";

function StarRating( { rating, className, id } ){
	const MAXIMUMRATING = 5;
	let ratingStars = new Array(MAXIMUMRATING);
	for( let i = 0; i < MAXIMUMRATING; i++){
        const classes = i < rating ? "active" : "inactive";
		ratingStars[i] = <Star className={`star ${classes}` } key={`star-${i}-${classes}`} />;
    }
    
    const classes = [
        "star-rating",
        className,
        rating === -1 ? "invalid" : ""
    ]

    return(
        <span className={ classes.join(" ").replace(/\s+/g, ' ')} id={id}>
            { ratingStars }
        </span>
    )
}

StarRating.defaultProps = {
    rating: -1,
    className: "",
    id: "",
}

export default StarRating;