import React from "react";
import { useState, useCallback, useEffect } from "react";
import { ReactComponent as Arrow } from "../images/arrow.svg";
import Heading from "../heading/Heading";
import "./Accordion.css";


function useScrollHeight() {
    const [scrollHeight, setScrollHeight] = useState(null);
    const ref = useCallback(node => {
      if (node !== null) {
        setScrollHeight(node.scrollHeight);
      }
    }, []);
    return [scrollHeight, ref];
  }

function Accordion ({
    title = null,
    children = null,
    defaultOpen = false,
    titleColour = "red",
    titleLevel = "h2",
    dangerousChildren = null,
}){
    const [scrollHeight, ref] = useScrollHeight();
    const [isOpen, setIsOpen] = useState( defaultOpen );
    const [height, setHeight] = useState( defaultOpen ? "100%" : "0px");

    function toggle() {
        setIsOpen( !isOpen )
        setHeight(
          isOpen ? "0px" : `${scrollHeight}px`
        );
      }

    useEffect( ()=>{
        if( scrollHeight !== null && isOpen ){
            setHeight( `${scrollHeight}px`)
        }
    }, [scrollHeight, isOpen])

    return (
        <div className="accordion">
            <button 
                className="accordion__header" 
                onClick={ toggle }
                aria-expanded={isOpen}
            >
                <div className="accordion__title" >
                    <Heading level={titleLevel} bold colour={titleColour}>{title}</Heading>
                </div>
                <Arrow className={`accordion__title-arrow${isOpen ? " active": ""}`}/>
            </button>
            { dangerousChildren ?
                <div 
                    style={{height: height}}
                    ref={ref} 
                    className="accordion__content"
                    dangerouslySetInnerHTML={{__html: dangerousChildren}}
                ></div>
            :
                <div 
                    style={{height: height}}
                    ref={ref} 
                    className="accordion__content"
                >{children}</div>
            }


        </div>
    )
}

export default Accordion