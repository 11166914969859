import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

import Header from "./components/header/Header";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Main from "./components/main/Main"

import VideoPage from "./pages/VideoPage/VideoPage"
import ThankYouPage from "./pages/ThankYouPage/ThankYouPage"
import CategoryPage from "./pages/CategoryPage/CategoryPage"
import SearchPage from "./pages/SearchPage/SearchPage"
import Signup from "./containers/Signup";
import AuthenticationRoute from "./components/AuthenticationRoute";

// const MyAccount = lazy(() => import('./containers/MyAccount'));
// const EditDetails = lazy(() => import('./containers/Edit-Details'));
// const ResetPassword = lazy(() => import('./containers/ResetPassword'));
// const ChangePassword = lazy(() => import('./containers/ChangePassword'));
// const ChangeEmail = lazy(() => import('./containers/ChangeEmail'));
// const Payment = lazy(() => import('./components/Payment'));

import MyAccount from "./containers/MyAccount";
import EditDetails from "./containers/Edit-Details";
import ResetPassword from "./containers/ResetPassword";
import ChangePassword from "./containers/ChangePassword";
import ChangeEmail from "./containers/ChangeEmail";
import Payment from "./components/Payment";

export default function Routes() {
    return (
        <Suspense fallback={
            <>
                <Header />
                <Main>
                    Loading...
                </Main>
            </>
        }>
            <Switch>
                <AuthenticationRoute exact path="/">
                    <Home />
                </AuthenticationRoute>

                <AuthenticationRoute UnauthenticatedRoute path="/login" backLinkActive borderedPage>
                    <Login />
                </AuthenticationRoute >

                <AuthenticationRoute UnauthenticatedRoute path="/signup" backLinkActive borderedPage>
                    <Signup />
                </AuthenticationRoute >

                <AuthenticationRoute UnauthenticatedRoute path="/login/reset" backLinkActive borderedPage>
                    <ResetPassword />
                </AuthenticationRoute>

                <AuthenticationRoute AuthenticatedRoute path="/settings/email">
                    <ChangeEmail />
                </AuthenticationRoute>

                <AuthenticationRoute AuthenticatedRoute path="/edit-details" backLinkActive borderedPage>
                    <EditDetails />
                </AuthenticationRoute>

                <AuthenticationRoute AuthenticatedRoute path="/my-account">
                    <MyAccount />
                </AuthenticationRoute>

                <AuthenticationRoute AuthenticatedRoute path="/change-password" backLinkActive>
                    <ChangePassword />
                </AuthenticationRoute>

                <AuthenticationRoute AuthenticatedRoute path="/payment" backLinkActive>
                    <Payment />
                </AuthenticationRoute>

                <AuthenticationRoute AuthenticatedRoute path="/thank-you" backLinkActive>
                    <ThankYouPage />
                </AuthenticationRoute>

                <AuthenticationRoute AuthenticatedRoute exact path="/video/:id" backLinkActive>
                    <VideoPage />
                </AuthenticationRoute>

                <AuthenticationRoute path="/category/:id">
                    <CategoryPage />
                </AuthenticationRoute>

                <AuthenticationRoute path="/search">
                    <SearchPage></SearchPage>
                </AuthenticationRoute>

                <Route>
                    <Header />
                    <NotFound />
                </Route>
            </Switch>
        </Suspense>
    );
}