import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {LoaderButton} from "../components/button";
import Heading from "../components/heading/Heading";
import LabeledInput from "../components/labeledInput/LabeledInput";
import LabeledSelect from "../components/labeledSelect/LabeledSelect";
import ConfirmationForm from "../components/confirmationForm/ConfirmationForm";
import Select from "../components/select/Select";
import Checkbox from "../components/checkbox/Checkbox";
import {useFormFields} from "../libs/hooksLib";
import {onError} from "../libs/errorLib";
import {Auth} from "aws-amplify";
import {config} from "../config";
import "./Signup.css";
import {useAppContext} from "../libs/contextLib";
import tick from '../components/images/tick.svg';

export default function Signup() {
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: "",
        confirmPassword: "",
        confirmationCode: "",
        forename: "",
        surname: "",
        dob: "",
        country: "",
        optina: "false",
        optinb: "false",
        title: ""
    });
    const history = useHistory();
    const { userHasAuthenticated } = useAppContext();
    const [ newUser, setNewUser ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const nameRegexExpression = /[^ A-Za-zÀ-ÖØ-öø-ÿ]+/g;

    let updateTitle = (e) => {
        handleFieldChange(e);
    }

    function getAge(DOB) {
        var today = new Date();
        var birthDate = new Date(DOB);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    function validateForm() {
        return (
            fields.email.length > 0 &&
            fields.password.length > 0 &&
            fields.confirmPassword === fields.password &&
            fields.forename.length > 0 && fields.forename.length < 256 &&
            fields.surname.length > 0 && fields.surname.length < 256 &&
            fields.dob.length > 0 &&
            fields.country.length > 0 &&
            isChecked === true
        );
    }

    var passwordChecks = {
        checkLength: () => fields.password.length > 7 && fields.password.length < 31,
        checkCase: () => /[a-z]/.test(fields.password) && /[A-Z]/.test(fields.password),
        checkNumbers: () => /\d/.test(fields.password),
        checkSpecialChars: () => /[^-\s\w]/.test(fields.password),
        getStyle: bool => {
            var color = bool ? '--lfc-grey' : '--lfc-red'
            return { color: `var(${color})` }
        },
        getClassName: isValid => isValid ? 'valid' : 'invalid',
        passwordIsValid: () => passwordChecks.checkLength()
                            && passwordChecks.checkCase()
                            && passwordChecks.checkNumbers()
                            && passwordChecks.checkSpecialChars()
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (!event.target.checkValidity()) {
            console.log("invalid")
        }

        if (getAge(new Date(fields.dob)) < 18) {
            alert('Sorry, users must be over 18 to continue.');
            return;
        }

        if (fields.password !== fields.confirmPassword) {
            alert('Your passwords do not match.');
            return;
        }

        if (!passwordChecks.passwordIsValid()) {
            alert('Your password does not meet the security criteria.');
            return;
        }

        setIsLoading(true);
        try {
            const newUser = await Auth.signUp({
                username: fields.email.toLowerCase(),
                password: fields.password,
                attributes:
                {
                    email: fields.email.toLowerCase(),
                    family_name: fields.surname,
                    name: fields.forename,
                    birthdate: fields.dob,
                    'custom:optInEmailMarketing': fields.optina,
                    'custom:optInEmailPartnerMar': fields.optinb,
                    'custom:title': fields.title,
                    'custom:registrationType' : "eAcademy",
                    'custom:countryCode': fields.country
                }
            });
            setIsLoading(false);
            setNewUser(newUser);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    // redirect handles
    function querystring(name, url = window.location.href) {
        name = name.replace(/[[]]/g, "\\$&");

        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
        const results = regex.exec(url);

        if (!results) {
            return null;
        }
        if (!results[2]) {
            return "";
        }

        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    const redirect = querystring("redirect");
    const paymentUrl = redirect === "" || redirect === null ? "/payment" : "/payment?redirect=" + redirect;

    function renderConfirmationForm() {
        if( ! config().renderConfirmationForm ){
            (async() => {
                await Auth.signIn(fields.email.toLowerCase(), fields.password);
                 history.push(paymentUrl)
                // lets push to my account instead, to scaffold some data to display the correct prompts to user
                // history.push('/');
                userHasAuthenticated(true);
            })()
        } else {
            return (
                <ConfirmationForm
                    username={ fields.email.toLowerCase() }
                    password={ fields.password }
                    paymentUrl={ paymentUrl }
                />
            );
        }
    }

    function renderForm() {
        return (
            <>
                <form onSubmit={handleSubmit}>
                    <Heading level="h1" colour="black">Create New<br /> Customer Account</Heading>
                    <p>You must be 18 or over to create an account</p>
                    <LabeledSelect
                        required
                        labelText="Title *"
                        id="title"
                        name="title"
                        autoFocus
                        onChange={updateTitle}
                        defaultValue={-1}

                    >
                        <option value="-1" disabled>--</option>
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Miss">Miss</option>
                        <option value="Ms">Ms</option>
                        <option value="Mstr">Mstr</option>
                        <option value="Mx">Mx</option>
                        <option value="Other">Other</option>
                        <option value="Prefer Not to Say">Prefer Not to Say</option>
                    </LabeledSelect>
                    <LabeledInput
                        required
                        labelText="Forename *"
                        name="forename"
                        value={fields.forename}
                        onChange={handleFieldChange}
                        regexLimiter={nameRegexExpression}
                    />
                    <LabeledInput
                        required
                        labelText="Surname *"
                        name="surname"
                        value={fields.surname}
                        onChange={handleFieldChange}
                        regexLimiter={nameRegexExpression}
                    />
                    <LabeledInput
                        required
                        labelText="Date of Birth *"
                        type="date"
                        name="dob"
                        min="1900-01-01"
                        placeholder="dd/mm/yyyy"
                        value={fields.dob}
                        onChange={handleFieldChange}
                    />
                      <LabeledSelect
                        labelText="Country *"
                        required
                        id="country"
                        name="country"
                        autoFocus
                        onChange={updateTitle}
                        defaultValue={0}
                        autocomplete="nope"
                    >
<option value="0" disabled>Please select...</option>
<option value="UK" selected="selected">United Kingdom</option>
<option value="EI">Ireland</option>
<option value="US">United States</option>
<option value="MY">Malaysia</option>
<option value="AU">Australia</option>
<option value="SQ">Singapore</option>
<option value="TH">Thailand</option>
<option value="NO">Norway</option>
<option value="ZA">South Africa</option>
<option value="-" disabled>--------------</option>
<option value="AF">Afghanistan</option>
<option value="AX">Aland Islands</option>
<option value="AL">Albania</option>
<option value="DZ">Algeria</option>
<option value="AS">American Samoa</option>
<option value="AO">Andorra</option>
<option value="AO">Angola</option>
<option value="AI">Anguilla</option>
<option value="AQ">Antarctica</option>
<option value="AG">Antigua and Barbuda</option>
<option value="AR">Argentina</option>
<option value="AM">Armenia</option>
<option value="AW">Aruba</option>
<option value="AC">Ascension</option>
<option value="AT">Austria</option>
<option value="AZ">Azerbaijan</option>
<option value="BS">Bahamas</option>
<option value="BH">Bahrain</option>
<option value="BD">Bangladesh</option>
<option value="BB">Barbados</option>
<option value="BY">Belarus</option>
<option value="BE">Belgium</option>
<option value="BZ">Belize</option>
<option value="BJ">Benin</option>
<option value="BM">Bermuda</option>
<option value="BT">Bhutan</option>
<option value="BO">Bolivia</option>
<option value="BA">Bosnia and Herzegovina</option>
<option value="BW">Botswana</option>
<option value="BV">Bouvet Island</option>
<option value="BR">Brazil</option>
<option value="IO">British Indian Ocean Territory</option>
<option value="BN">Brunei Darussalam</option>
<option value="BG">Bulgaria</option>
<option value="BF">Burkina Faso</option>
<option value="BI">Burundi</option>
<option value="KH">Cambodia</option>
<option value="CM">Cameroon</option>
<option value="CA">Canada</option>
<option value="CV">Cape Verde</option>
<option value="KY">Cayman Islands</option>
<option value="CF">Central African Republic</option>
<option value="TD">Chad</option>
<option value="CL">Chile</option>
<option value="CN">China</option>
<option value="CX">Christmas Island</option>
<option value="CC">Cocos (Keeling) Islands</option>
<option value="CO">Colombia</option>
<option value="KM">Comoros</option>
<option value="CG">Congo</option>
<option value="CD">Congo (Democratic Republic)</option>
<option value="CK">Cook Islands</option>
<option value="CR">Costa Rica</option>
<option value="CI">Cote d'Ivoire</option>
<option value="HR">Croatia</option>
<option value="CU">Cuba</option>
<option value="CW">Curacao</option>
<option value="CY">Cyprus</option>
<option value="CZ">Czech Republic</option>
<option value="DK">Denmark</option>
<option value="DJ">Djibouti</option>
<option value="DM">Dominica</option>
<option value="DO">Dominican Republic</option>
<option value="EC">Ecuador</option>
<option value="EG">Egypt</option>
<option value="SV">El Salvador</option>
<option value="GQ">Equatorial Guinea</option>
<option value="ER">Eritrea</option>
<option value="EE">Estonia</option>
<option value="ET">Ethiopia</option>
<option value="FK">Falkland Islands</option>
<option value="FO">Faroe Islands</option>
<option value="FJ">Fiji</option>
<option value="FI">Finland</option>
<option value="FR">France</option>
<option value="GF">French Guiana</option>
<option value="PF">French Polynesia</option>
<option value="TF">French Southern Territories</option>
<option value="GA">Gabon</option>
<option value="GM">Gambia</option>
<option value="GE">Georgia</option>
<option value="DE">Germany</option>
<option value="GH">Ghana</option>
<option value="GI">Gibraltar</option>
<option value="GR">Greece</option>
<option value="GL">Greenland</option>
<option value="GD">Grenada</option>
<option value="GP">Guadeloupe</option>
<option value="GU">Guam</option>
<option value="GT">Guatemala</option>
<option value="GG">Guernsey</option>
<option value="GN">Guinea</option>
<option value="GW">Guinea-Bissau</option>
<option value="GY">Guyana</option>
<option value="HT">Haiti</option>
<option value="HM">Heard &amp; McDonald Islands</option>
<option value="HN">Honduras</option>
<option value="HK">Hong Kong</option>
<option value="HU">Hungary</option>
<option value="IS">Iceland</option>
<option value="IN">India</option>
<option value="ID">Indonesia</option>
<option value="IR">Iran</option>
<option value="IQ">Iraq</option>
<option value="IM">Isle of Man</option>
<option value="IL">Israel</option>
<option value="IT">Italy</option>
<option value="JM">Jamaica</option>
<option value="JP">Japan</option>
<option value="JE">Jersey</option>
<option value="JO">Jordan</option>
<option value="KZ">Kazakhstan</option>
<option value="KE">Kenya</option>
<option value="KI">Kiribati</option>
<option value="KP">Korea North</option>
<option value="KR">Korea South</option>
<option value="KW">Kuwait</option>
<option value="KG">Kyrgyzstan</option>
<option value="LA">Laos</option>
<option value="LV">Latvia</option>
<option value="LB">Lebanon</option>
<option value="LS">Lesotho</option>
<option value="LR">Liberia</option>
<option value="LY">Libya</option>
<option value="LI">Liechtenstein</option>
<option value="LT">Lithuania</option>
<option value="LU">Luxembourg</option>
<option value="MO">Macao</option>
<option value="MK">Macedonia</option>
<option value="MG">Madagascar</option>
<option value="MW">Malawi</option>
<option value="MV">Maldives</option>
<option value="ML">Mali</option>
<option value="MT">Malta</option>
<option value="MH">Marshall Islands</option>
<option value="MQ">Martinique</option>
<option value="MR">Mauritania</option>
<option value="MU">Mauritius</option>
<option value="YT">Mayotte</option>
<option value="MX">Mexico</option>
<option value="FM">Micronesia</option>
<option value="MD">Moldova</option>
<option value="MC">Monaco</option>
<option value="MN">Mongolia</option>
<option value="ME">Montenegro</option>
<option value="MS">Montserrat</option>
<option value="MA">Morocco</option>
<option value="MZ">Mozambique</option>
<option value="MM">Myanmar</option>
<option value="NA">Namibia</option>
<option value="NR">Nauru</option>
<option value="NP">Nepal</option>
<option value="NL">Netherlands</option>
<option value="NC">New Caledonia</option>
<option value="NZ">New Zealand</option>
<option value="NI">Nicaragua</option>
<option value="NE">Niger</option>
<option value="NG">Nigeria</option>
<option value="NU">Niue</option>
<option value="NF">Norfolk Island</option>
<option value="MP">Northern Mariana Islands</option>
<option value="OM">Oman</option>
<option value="PK">Pakistan</option>
<option value="PW">Palau</option>
<option value="PS">Palestinian Territory</option>
<option value="PA">Panama</option>
<option value="PG">Papua New Guinea</option>
<option value="PY">Paraguay</option>
<option value="PE">Peru</option>
<option value="PH">Philippines</option>
<option value="PN">Pitcairn</option>
<option value="PL">Poland</option>
<option value="PT">Portugal</option>
<option value="PR">Puerto Rico</option>
<option value="QA">Qatar</option>
<option value="RE">Réunion</option>
<option value="RO">Romania</option>
<option value="RU">Russian Federation</option>
<option value="RW">Rwanda</option>
<option value="GP">Saint Barthelemy</option>
<option value="SH">Saint Helena</option>
<option value="KN">Saint Kitts and Nevis</option>
<option value="LC">Saint Lucia</option>
<option value="MF">Saint Martin (French)</option>
<option value="PM">Saint Pierre and Miquelon</option>
<option value="VC">Saint Vincent and the Grenadines</option>
<option value="WS">Samoa</option>
<option value="SM">San Marino</option>
<option value="ST">Sao Tome and Principe</option>
<option value="SA">Saudi Arabia</option>
<option value="SN">Senegal</option>
<option value="RS">Serbia</option>
<option value="SC">Seychelles</option>
<option value="SL">Sierra Leone</option>
<option value="SX">Sint Maarten (Dutch)</option>
<option value="SK">Slovakia</option>
<option value="SL">Slovenia</option>
<option value="SB">Solomon Islands</option>
<option value="SO">Somalia</option>
<option value="GS">South Georgia</option>
<option value="ES">Spain</option>
<option value="LK">Sri Lanka</option>
<option value="SD">Sudan</option>
<option value="SR">Suriname</option>
<option value="SJ">Svalbard and Jan Mayen</option>
<option value="SZ">Swaziland</option>
<option value="SE">Sweden</option>
<option value="CH">Switzerland</option>
<option value="SY">Syria</option>
<option value="TW">Taiwan</option>
<option value="TJ">Tajikistan</option>
<option value="TZ">Tanzania</option>
<option value="TL">Timor-Leste</option>
<option value="TG">Togo</option>
<option value="TK">Tokelau</option>
<option value="TO">Tonga</option>
<option value="TT">Trinidad and Tobago</option>
<option value="TN">Tunisia</option>
<option value="TR">Turkey</option>
<option value="TM">Turkmenistan</option>
<option value="TC">Turks and Caicos Islands</option>
<option value="TV">Tuvalu</option>
<option value="UG">Uganda</option>
<option value="UA">Ukraine</option>
<option value="AE">United Arab Emirates</option>
<option value="UY">Uruguay</option>
<option value="UM">US Minor Outlying Islands</option>
<option value="UZ">Uzbekistan</option>
<option value="VU">Vanuatu</option>
<option value="VA">Vatican City</option>
<option value="VE">Venezuela</option>
<option value="VN">Vietnam</option>
<option value="VG">Virgin Islands British</option>
<option value="VI">Virgin Islands U.S.</option>
<option value="WF">Wallis and Futuna</option>
<option value="EH">Western Sahara</option>
<option value="YE">Yemen</option>
<option value="ZM">Zambia</option>
<option value="ZW">Zimbabwe</option>
</LabeledSelect>

                    <Heading level="h2" colour="black">Sign-in Information</Heading>

                    <LabeledInput
                        required
                        labelText="Email *"
                        type="email"
                        name="email"
                        value={fields.email}
                        onChange={handleFieldChange}
                    />
                    <LabeledInput
                        required
                        labelText="Password *"
                        name="password"
                        type="password"
                        value={fields.password}
                        onChange={handleFieldChange}
                    />
                    <p className="pwstrength">
                    For your security please ensure your password is:
                        <br/><br/>
                        <span style={{ '--tick-icon': tick }} className={passwordChecks.getClassName(passwordChecks.checkLength())}>
                            Between 8 and 30 characters long
                        </span><br/>
                        <span className={passwordChecks.getClassName(passwordChecks.checkCase())}>
                            Contain a mix of upper (capital) and lower case letters
                        </span><br/>
                        <span className={passwordChecks.getClassName(passwordChecks.checkNumbers())}>
                            Contain at least one number
                        </span><br/>
                        <span className={passwordChecks.getClassName(passwordChecks.checkSpecialChars())}>
                            Contain at least one special character
                        </span>
                    </p>

                    <LabeledInput
                        required
                        labelText="Confirm Password *"
                        name="confirmPassword"
                        type="password"
                        value={fields.confirmPassword}
                        onChange={handleFieldChange}
                    />
                    <p
                      className={`passwords-do-not-match ${fields.confirmPassword.length === 0 || fields.confirmPassword === fields.password ? 'hidden' : ''}`}>
                        Passwords do not match</p>

                    <Heading level="h2" colour="black">Contact Permission</Heading>
                    <p>We want to be able to keep in touch and send you the information you would like to receive by email.  Please let us know what you would like us to send you by clicking below.</p>
                    <Heading level="h3" colour="black" bold>Liverpool FC news, Products, services and offers</Heading>
                    <label htmlFor="optina">Please keep me updated on Liverpool FC, Liverpool FC woman and the LFC Foundation.</label>
                    <Select
                        onChange={updateTitle}
                        value={fields.optina}
                        id="optina"
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </Select>

                    <Heading level="h3" colour="black" bold>Liverpool FC Sponsors and Partners</Heading>
                    <label htmlFor="optinb">Please keep me updated on official sponsors and partners products, events, news and offers available to Liverpool FC</label>
                    <Select
                        onChange={updateTitle}
                        value={fields.optinb}
                        id="optinb"
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </Select>

                    <Checkbox
                    name="tanda"
                    required
                    onChange={(event) => setIsChecked(event.currentTarget.checked)}
                    checked={isChecked}
                    labelText={
                        <div style={{maxWidth:"80%"}}>I confirm that I am over 18 years old, I have read, understood and agree to the <a href="#">Privacy Policy</a> and <a href="#">Terms and Conditions.</a></div>

                    }
                />

                    <LoaderButton
                        block
                        type="submit"
                        isLoading={isLoading}
                        disabled={!validateForm()}
                    >
                        Continue to membership
                    </LoaderButton>
                </form>
            </>
        );
    }

    return (
        <div className="Signup">
            {newUser === null ? renderForm() : renderConfirmationForm()}
        </div>
    );
}
