import React from "react";
import "./LoadingClay.css"

function LoadingClay({
    className = '',
    width = '12rem',
    height = '1.6rem',
    colour = 'grey',
}){

    const validColours = ['red', 'grey', 'white'];
    const safeColour = colour ? colour.toLowerCase() : '';
    const selectedColour = validColours.includes(safeColour) ? safeColour : 'grey';

    const classes = [
        'LoadingClay',
        className,
        selectedColour
    ]
    return (
        <span 
            style={{width: width, height: height}}
            className={classes.join(' ')}
            >
        </span>
    )
}

export default LoadingClay;