import React, { useState } from "react";
import { useCookies } from "react-cookie"
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as LfcLogo } from "../images/LFC_logo.svg";
import { Navigation, NavigationSearch, RecentSearch } from "../navigation";
import { config } from "../../config"
import "./NavBar.css";

const SEARCHCOOKIE = config().searchCookie
const SEARCHCOOKIEDELIMITER = config().searchCookieDelimiter

function NavBar({ isOpen, isActive, onClick, ...props }) {
    const [ searchIsOpen, setSearchIsOpen ] = useState( false )
    const [ cookies, ] = useCookies([ SEARCHCOOKIE ]);
    const history = useHistory();
    const handleBackLink = (e) => {
        e.preventDefault();
        history.goBack();
    }

    let searches = [];
    const searchesCookie = cookies[ SEARCHCOOKIE ];
    if( searchesCookie !== undefined ) {
        searches = searchesCookie.split( SEARCHCOOKIEDELIMITER );
    }

    return(
        <div style={{ borderBottom: "2px solid var(--lfc-red)"}}>
            <div className={`NavBar${ isActive ? " active" : ""}`}>
                { isActive && <Link className="NavBar__back" to="/" onClick={ handleBackLink } >&lt;</Link>}
                <div className="NavBar__left" id="navleft">
                    <Navigation isOpen={ isOpen } onClick={ onClick }></Navigation>
                    <div className="NavBar__lfc"><Link to="/"><LfcLogo  /></Link></div>
                </div>
                <div className="NavBar__right" id="navright">
                    <NavigationSearch
                        className="NavBar__search"
                        isOpen={ searchIsOpen }
                        onClick= { () => setSearchIsOpen( !searchIsOpen ) }
                    ></NavigationSearch>
                </div>
            </div>
            { ! isActive &&
                <RecentSearch
                    isOpen={ searchIsOpen }
                    searches={ searches }
                ></RecentSearch> }
        </div >
    )
}

export default NavBar;
