import React from "react";
import "./Select.css"

function Select( { 
    children = null,
    className = null,
    id = null,
    labelText = "Select",
    onChange = null,
    autoFocus = false,
    onSelect = null,
    ...props
} ){
    return(
        <select
            autoFocus={ autoFocus }
            className={ `Select${ className ? " " + className : ""}` }
            id={ id }
            onChange={ onChange }
            onSelect={ onSelect }
            { ...props } 
        >
            { children }
        </select>
    )
}

export default Select;