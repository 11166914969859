import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie"
import { ReactComponent as Search } from "../images/search.svg";
import { config } from "../../config"
import "./NavigationSearch.css"

const MAXRECENT = 4;
const SEARCHCOOKIE = config().searchCookie
const SEARCHCOOKIEDELIMITER = config().searchCookieDelimiter

function NavigationSearch({
    className = "",
    isOpen = false,
    onClick = null
}){
    const [ searchTerm, setSearchTerm ] = useState("");
    const [ cookies, setCookie, /* removeCookie */ ] = useCookies([ SEARCHCOOKIE ]);
    let classNames = ["NavigationSearch"];
    isOpen && classNames.push("isOpen");
    className.length > 0 && classNames.push( className );

    const onSubmitSearch = (e) => {
        if( ! isOpen ){
            e.preventDefault()
            return false;
        }

        const searchesCookie = cookies[ SEARCHCOOKIE ];
        if( searchesCookie ){
            const prevCookie = cookies[ SEARCHCOOKIE ].split( SEARCHCOOKIEDELIMITER )
            let newCookie = prevCookie.concat( [ searchTerm ] )
            if( newCookie.length > MAXRECENT ){
                newCookie.shift();
            }
            if( searchTerm.length > 0 ) {
                setCookie( SEARCHCOOKIE , newCookie.join( SEARCHCOOKIEDELIMITER ) );
            }
        } else{
            if( searchTerm.length > 0 ) {
                setCookie( SEARCHCOOKIE , searchTerm );
            }
        }
    }

    useEffect( ()=>{
        if( isOpen ){
            setSearchTerm("")
        }
    }, [ isOpen ])

    // const buttonType = isOpen ? "submit" : "button"
    return (
        <div className={ classNames.join( " " ) }>
            <form onSubmit={ onSubmitSearch } action="/search" method="get">
                <input
                    type="text"
                    className="NavigationSearch__input"
                    placeholder="Search eAcademy here..."
                    value={searchTerm}
                    name="q"
                    id="q"
                    onChange={ (e)=> setSearchTerm(e.target.value) }></input>
                    <button type={ "button" } className="NavigationSearch__button">
                        <Search onClick={ onClick }></Search>
                    </button>
            </form>

        </div>
    )
}

export default NavigationSearch
