import React from "react";
import { useRef } from 'react';
import "./Checkbox.css"

function Checkbox ({
    autoComplete = null,
    autoFocus = false,
    className,
    disabled = false,
    id = null,
    labelText ="",
    name,
    failedValidation = false,
    value,
    required = false,
    ...props 
    }) {
    const inputRef = useRef(null);

    return ( 
        <div className={`checkbox ${ className || "" }`} id={ id } >
            <label className={`checkbox__label ${ failedValidation ? "invalid" : ""}`} >
                <input 
                    className="checkbox__input"
                    autoComplete={ autoComplete }
                    autoFocus={ autoFocus }
                    disabled={ disabled }
                    id={ name || "checkbox" }
                    name={ name || "checkbox" } 
                    ref={ inputRef }
                    type={ "checkbox" } 
                    value={ value }
                    required={ required }
                    onKeyDown = { (e) => {
                        if(props.onKeyDown)
                        {
                            props.onKeyDown(e);
                        }
                    }}
                    onChange={ ( e )=> { 
                        if(props.onChange) {
                            props.onChange(e);
                        }
                    }}
                ></input>
                <div className="checkbox__virtual"></div>
                { labelText }
            </label>
        </div>
    )
}

export default Checkbox