import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import Heading from "../components/heading/Heading";
import LabeledInput from "../components/labeledInput/LabeledInput";
import PromoCode from "../components/promoCode/PromoCode";
import { useFormFields } from "../libs/hooksLib";
import { Auth } from "aws-amplify";
import axios from "axios";
import { onError } from "../libs/errorLib";
import { config } from "../config";
import { useAppContext } from "../libs/contextLib";
import { useToast } from "../components/toast";
import "./BillingForm.css";

function BillingForm({ onSubmit }) {
  const toast = useToast();

  const SubscriptionTerm = {
    ANNUAL: 0,
    MONTHLY: 1,
  };

  Object.freeze(SubscriptionTerm);

  const history = useHistory();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    name: "",
    promocode: "",
  });
  const { setOnloadTrigger } = useAppContext();

  const [isPromoValidating, setIsPromoValidating] = useState(false);

  const payWithPromo = async () => {
    setIsPromoValidating(true);
    
    try {
      const userinfo = await Auth.currentUserInfo();

      const promoObject = {
        cognitoid: userinfo.username,
        lfcId: userinfo.attributes['custom:myLfcId'],
        promo: fields.promocode,
        idempotency: uuidv4(),
        email: fields.email,
        firstname: userinfo.attributes.name,
        lastname: userinfo.attributes.family_name,
        term: SubscriptionTerm.MONTHLY,
      };

      const { data } = await axios.post(
        `${config().stripe.apiUrl}/paywithpromo`,
        promoObject
      );

      if (data.isSuccessful) {
        history.push("/thank-you");

        toast.add("Thank you. Welcome to eAcademy.");

        setOnloadTrigger(true);
      } else {
        toast.add(
          "Subscription failed. Please check your promo code and try again!"
        );
      }
    } catch (error) {
      alert(error);
    }

    setIsPromoValidating(false);
  };

  const billingMeta = () => {

    return (
      <>
        <Heading level="h2" colour="black">
          Subscribe to eAcademy
        </Heading>

        <p className="BillingMeta__content">No card required, just your promo code!</p>
        <p className="BillingMeta__content">Once your code is verified, your membership will be activated, giving you instant access to all training videos.</p>
        <br></br>
      </>
    );
  };

  return (
    <>
      <div className="BillingMeta">{billingMeta()}</div>

      <form className="BillingForm">
        <Heading level="h3" colour="black" className="pay-with-card">
          Pay with voucher
        </Heading>
        <LabeledInput
          labelText="Email"
          type="email"
          name="email"
          value={fields.email}
          onChange={handleFieldChange}
        />
        <div className="Form_row">
          <PromoCode
            value={fields.promocode}
            onChange={handleFieldChange}
            onClick={payWithPromo}          
            isLoading={isPromoValidating}
          ></PromoCode>
        </div>
      </form>
    </>
  );
}

export default BillingForm;
