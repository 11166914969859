import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import Header from "../components/header/Header";
import Main from "../components/main/Main";

function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function AuthenticationRoute( { 
    AuthenticatedRoute = false,
    UnauthenticatedRoute = false,
    children, 
    backLinkActive = false,
    borderedPage = false,
    ...rest } ) {

    const { isAuthenticated } = useAppContext();
    const { pathname, search } = useLocation();
    const redirect = querystring("redirect");
    
    const renderRoute = () => {
        if ( AuthenticatedRoute && ! isAuthenticated ){
            return <Redirect to={`/login?redirect=${pathname}${search}`} />;
        }
        if ( UnauthenticatedRoute && isAuthenticated ){
            return <Redirect to={redirect === "" || redirect === null ? "/" : redirect} />;
        }

        return (
            <>
                <Header backLinkActive={backLinkActive}></Header>
                <Main borderedPage={borderedPage}>{children}</Main>
            </>
        )
    }

    return (
        <Route {...rest}>
            { renderRoute() }
        </Route>
    );
}

export default AuthenticationRoute;