import React from "react";
import Heading from '../../components/heading/Heading'
import StarRating from "../../components/starRating/StarRating";
import CategoryBubble from "../../components/categoryBubble/CategoryBubble";
import LoadingClay from "../../components/loadingClay/LoadingClay";
import "./VideoPage.css";

function VideoPageLoader( ){

    return (
        <main className="videoPage">
        <div className="videoPage__unloaded-Img"></div>
        <div className="videoPage__content">
            <div className="videoMeta">
                <Heading level="h1" colour={"red"}>
                    <LoadingClay />
                </Heading>
                <CategoryBubble  />
                <span className="duration"><LoadingClay width={"4rem"} /></span>
                <StarRating />
            </div>
        </div>
    </main>
    )
}

export default VideoPageLoader;