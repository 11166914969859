import React from "react";
import Button from "./Button";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import "./LoaderButton.css";

export default function LoaderButton({
    isLoading,
    className = "",
    disabled = false,
    block = false,
    ...props
}) {
    return (
        <Button
            className={`LoaderButton ${ block ? "LoaderButton__block" : "" } ${className}`}
            disabled={ disabled || isLoading }
            {...props}
        >
            {  isLoading && <LoadingSpinner className="LoaderButton__loader" /> }
            
            <span className={`LoaderButton__text ${isLoading ? "loading":""}`}>
                { props.children }
            </span>
        </Button>
    );
}