import { useState, useEffect, useMemo } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import { config } from "config";

export interface AccountResponseModel {
  hasActiveMembership: boolean;
  expiryDate?: Date | null;
}

export const useMembershipDetails = () => {
  const [membershipData, setMembershipData] = useState<AccountResponseModel>({
    hasActiveMembership: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const cognitoSubject = useMemo(async () => {
    const userInfo = await Auth.currentUserInfo();
    if (!userInfo) return null;

    return userInfo.attributes['custom:myLfcId'];
  }, []);

  useEffect(() => {

    const fetchMembeshipDetails = async () => {

      const lfcId = await cognitoSubject;

      if (!lfcId) return;

      try {
        setIsLoading(true);
        const response = await axios.get(`${config().apiUrl}/api/account?lfcId=${lfcId}`);
        setMembershipData(response.data);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMembeshipDetails();
  }, [cognitoSubject]);

  return { membershipData, isLoading, error };
};
