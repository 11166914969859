import React from "react"
import Heading from "../../components/heading/Heading";
import LoadingClay from "../../components/loadingClay/LoadingClay";
import "./CategoryPage.css"

function CategoryPageLoader(){

    const falseVideo = (
        <div className="videocard wide">
            <LoadingClay width={"100%"} height={"20rem"} />
            <p><LoadingClay width={"15rem"} /></p>
            <p><LoadingClay /></p>
        </div>)

    return (
        <main className="categoryPage">
            <Heading level="h1" className="categoryPage__title" bold><LoadingClay colour="red"/></Heading>
            <section className="categoryPage__videos">
                { falseVideo }
                { falseVideo }
                { falseVideo }
            </section>
        </main>
    )
}

export default CategoryPageLoader;