import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { LoaderButton } from "../components/button";
import LabeledInput from "../components/labeledInput/LabeledInput";
import LabeledSelect from "../components/labeledSelect/LabeledSelect";
import Heading from "../components/heading/Heading";
import { Link } from "react-router-dom";
import { onError } from "../libs/errorLib";
import { useToast } from '../components/toast';
import LoadingSpinner from '../components/loadingSpinner/LoadingSpinner';
import "./EditDetails.css"


function EditDetails() {
    const toast = useToast();
    const [isConfirming, setIsconfirming] = useState(false);
    const [title, setTitle] = useState();
    const [forename, setForename] = useState();
    const [surname, setSurname] = useState();
    const [email, setEmail] = useState();
    const [dob, setDob] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getUserAttributes();
    }, []);

    async function getUserAttributes(event) {
        const userinfo = await Auth.currentUserInfo();
        setForename(userinfo.attributes.name);
        setSurname(userinfo.attributes.family_name);
        setDob(userinfo.attributes.birthdate);
        setTitle(userinfo.attributes['custom:Title']);
        setEmail(userinfo.attributes.email);

        setIsLoading(false);
    }

    function validateConfirmForm() {
        return forename.length > 0 && surname.length > 0 && title?.length > 0 && dob.length > 0;
    }

    async function handleConfirmClick(event) {
        event.preventDefault();
        setIsconfirming(true);
        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, { 'birthdate': dob });
            await Auth.updateUserAttributes(user, { 'name': forename });
            await Auth.updateUserAttributes(user, { 'family_name': surname });
            await Auth.updateUserAttributes(user, { 'custom:title': title });

            toast.add("Saved");
            setIsconfirming(false);
        } catch (error) {
            onError(error);
            setIsconfirming(false);
        }
    }


    return (
        <div className="Edit-Details">
            <Heading level="h1" colour="black">Account information</Heading>
            { isLoading ? 
                <div style={{display: "flex", justifyContent: "center"}}>
                    <LoadingSpinner />
                </div> 
                :
                <form onSubmit={handleConfirmClick}>
                    <LabeledSelect
                        labelText="Title"
                        defaultValue={title}
                        id="title"
                        name="title"
                        autoFocus
                        onChange={(e) => {
                            setTitle(e.target.value)
                        }} 
                    >
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Dr">Dr</option>
                    </LabeledSelect>

                    <LabeledInput
                        name="forename"
                        labelText="Forename"
                        value={forename}
                        onChange={(e) => setForename(e.target.value)}
                    />
                    <LabeledInput
                        name="surname"
                        labelText="Surname"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                    />
                    <LabeledInput
                        name="dob"
                        labelText="Date of Birth"
                        type="date"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                    />
                    <LabeledInput
                        name="email"
                        labelText="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <LoaderButton
                        block
                        type="submit"
                        isLoading={isConfirming}
                        disabled={!validateConfirmForm()}>Save</LoaderButton>
                </form>
            }
            <Link 
                className="back-button red" 
                to="/my-account"
            >
                Back
            </Link>
        </div>
    )
}

export default EditDetails;