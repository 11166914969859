import React from "react";
import "./Videocard.css";
import StarRating from "../starRating/StarRating";
import { Link } from "react-router-dom";

function Videocard({ category, title, duration, rating, id, className, imageSrc, alignwide, nid }) {
	className = className.length > 0 ? " " + className : "";
	let bgImg = null;
	if (imageSrc) {
		bgImg = { backgroundImage: `url(${imageSrc})` }
	}

	return (
		<Link 
			to={"/video/" + nid} 
			className={`videocard${alignwide ? " wide" : ""}${className}`} 
			id={id}
		>
			<div className="thumbnail" style={bgImg} >
				{category && <div className="category">{category}</div>}
			</div>
			<div className="title">{title}</div>
			<div className="videodescription">
				<span className="duration">{duration}</span>
				<StarRating rating={rating} className="difficulty"></StarRating>
			</div>
		</Link>
	);
}

Videocard.defaultProps = {
	category: null,
	title: "Title",
	duration: "0:00",
	rating: "1",
	className: "",
	id: "",
	imageSrc: null,
	alignwide: false,
	nid: -1
}
export default React.memo(Videocard);
