import React, { useEffect, useState } from "react"
import { useDebouncedCallback } from 'use-debounce';
import "./ToTop.css"

function ToTop(){
    const [ isVisible, setIsVisible ] = useState( document.body.scrollTop > 20 || document.documentElement.scrollTop > 20 );

    // Debounce callback
    const [ scrollHandler ] = useDebouncedCallback(
        // function
        () => {
            setIsVisible(document.body.scrollTop > 20 || document.documentElement.scrollTop > 20)
        },
        // delay in ms
        100
    );

    useEffect( ()=> {
        document.addEventListener("scroll", scrollHandler );
        return () => document.removeEventListener("scroll", scrollHandler);
    }, [ isVisible, scrollHandler ] )


    const goToTop = () =>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    return (
        <>
        { isVisible && 
            <div className="ToTop" onClick={ goToTop }>
                <button className="ToTop__button">To Top</button>
                <div className="ToTop__arrow">
                    <span></span>
                    <span></span>
                </div>
            </div>
        }
        </>
    )
}

export default ToTop;