import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import Heading from "../components/heading/Heading";
import { Button } from "../components/button";
import LoadingSpinner from "../components/loadingSpinner/LoadingSpinner";
import { useAppContext } from "../libs/contextLib";
import { useToast } from "../components/toast";
import { useMembershipDetails } from "hooks/useMembershipDetails";
import "./MyAccount.css";

const MyAccount = () => {
  const history = useHistory();
  const toast = useToast();
  const { userHasAuthenticated } = useAppContext();
  const { membershipData, isLoading } = useMembershipDetails();

  const [userDetails, setUserdetails] = useState({});

  useEffect(() => {
    async function getUserDetails() {
      const userinfo = await Auth.currentUserInfo();
      setUserdetails(userinfo);
    }

    getUserDetails();
  }, []);

  const handleLogout = async () => {
    toast.add("Signed Out");

    userHasAuthenticated(false);

    await Auth.signOut();

    history.push("/");
  };

  const redeemCodeView = () => (
    <>
      <Heading level="h2" bold colour="black">
        You are not subscribed to LFC eAcademy.
      </Heading>
      <Button to="/payment" className="MyAccount__unpaid-button">
        Redeem Access Code
      </Button>
    </>
  );

  const existingSubscriptionView = () => (
    <>
      <Heading level="h2" bold colour="black">
        You are subscribed to LFC eAcademy.
      </Heading>
      <p>
        Membership expires:{" "}
        {new Date(membershipData.expiryDate).toLocaleDateString("en-GB")}
      </p>
    </>
  );

  const expiredSubscriptionView = () => (
    <>
      <Heading level="h2" bold colour="black">
        Your LFC eAcademy membership has expired.
      </Heading>
      <p>
        Expired on:{" "}
        {membershipData.expiryDate
          ? new Date(membershipData.expiryDate).toLocaleDateString("en-GB")
          : "Never"}
      </p>
      <Button to="/payment" className="MyAccount__unpaid-button">
        Redeem New Access Code
      </Button>
    </>
  );

  const renderMembershipStatus = () => {
    if (membershipData == null) return redeemCodeView();
    if (membershipData.hasActiveMembership) return existingSubscriptionView();
    if (membershipData.expiryDate) return expiredSubscriptionView();
    return redeemCodeView();
  };

  return (
    <>
      <div className="MyAccount">
        <Heading level="h1" bold colour="black">
          My Account{" "}
          <button onClick={handleLogout} className="SignOutButton">
            Sign out
          </button>
        </Heading>
        <Heading level="h2" bold colour="black">
          Account Information
        </Heading>
        {isLoading || !userDetails.attributes ? (
          <>
            <LoadingSpinner />
            <p className="screen-reader-text">Loading</p>
          </>
        ) : (
          <div>
            <p>
              {userDetails.attributes["custom:title"] &&
                userDetails.attributes["custom:title"] + " "}
              {userDetails.attributes["name"] + " "}
              {userDetails.attributes["family_name"]}
              <br />
              {userDetails.attributes["email"]}
            </p>{" "}
            {renderMembershipStatus()}
          </div>
        )}
      </div>
    </>
  );
};

export default MyAccount;
