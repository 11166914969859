import React from "react";
import "./Quote.css"

function Quote( { author, quote, signatureSrc, className, id, signatureAlt } ){
    const sig =  signatureSrc !== null ? signatureSrc : null;

    return(
        <blockquote className={`quote ${ className}`} id={id}>
            <p>{quote}</p>
            { sig && <img src={sig} alt={signatureAlt}></img>}
            <footer>
                { author }
            </footer>
        </blockquote>
    )
}

Quote.defaultProps = {
    author: "Author",
    quote: "",
    signatureSrc: null,
    signatureAlt: "",
    id: "",
    className: "",
}

export default Quote;