import React from "react"
import LabeledInput from "../labeledInput/LabeledInput";
import { LoaderButton } from "../button";
import "./PromoCode.css"

function PromoCode({
    isApplied = false,
    value,
    onChange = null,
    onClick = null,
    isLoading = false }) {

    const HandleOnChange = onChange ? (e) => onChange(e) : null

    return (
        <div className="PromoCode">
            <LabeledInput
                labelText="Promo Code"
                type="text"
                name="promocode"
                id="promocode"
                value={value}
                onChange={HandleOnChange}
                placeholder="Enter code here"
            />

            <LoaderButton
                isLoading={isLoading}
                className="PromoCode__apply"
                type="button"
                onClick={
                    async () => onClick(value)
                }> {isApplied ? "Applied" : "Apply"}</LoaderButton>
        </div>
    )
}

export default PromoCode;