import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import { ReactComponent as Facebook } from "../images/Social-Facebook.svg";
import { ReactComponent as Instagram } from "../images/Social-Instagram.svg";
import { ReactComponent as Twitter } from "../images/Social-Twitter.svg";
import { ReactComponent as Menu } from "../images/menu.svg";
import { ReactComponent as Close } from "../images/close.svg";
import "./Navigation.css";

function Navigation({ isOpen, onClick }) {
  const { contentData } = useAppContext();

  const categoryLinks = () => {
    if (contentData) {
      return Object.keys(contentData).map((category) => (
        <li key={category}>
          <NavLink to={"/category/" + category} onClick={(e) => onClick(e)}>
            {category
              .split("-")
              .map((word) => word[0].toUpperCase() + word.slice(1))
              .join(" ")}
          </NavLink>
        </li>
      ));
    }
    return (
      <li>
        loading
        <LoadingSpinner style={{ display: "inline-block" }} />
      </li>
    );
  };

  return (
    <div className={`navigation ${isOpen ? "isOpen" : "isClosed"}`}>
      <button
        className="navigation__toggle"
        aria-expanded={isOpen}
        onClick={(e) => onClick(e)}
      >
        <span className="screen-reader-text">Open Menu</span>
        <Menu className="menu" />
      </button>
      <div className="navigation__inner">
        <div className="navigation__scrollwrap">
          <button
            className="navigation__close"
            aria-expanded={isOpen}
            onClick={(e) => onClick(e)}
          >
            <span className="screen-reader-text">Close Menu</span>
            <Close />
          </button>
          <ul className="navlist">
            <li>
              <NavLink exact to="/" onClick={(e) => onClick(e)}>
                Home
              </NavLink>
            </li>
            {categoryLinks()}
          </ul>
          <Link to="/my-account" onClick={(e) => onClick(e)}>
            <div className="navaccount">My Account</div>
          </Link>
          <div className="navigation__social">
            <h2 className="navigation__social-title">FOLLOW US</h2>
            <div className="navigation__social-holder">
              <a
                href="https://www.instagram.com/lfcinternationalacademy"
                className="Instagram"
              >
                <Instagram />
                <br />
                Instagram
              </a>
              <a href="https://twitter.com/LFCSoccerSchool" className="Twitter">
                <Twitter />
                <br />
                Twitter
              </a>
              <a
                href="https://www.facebook.com/LFCInternationalAcademy"
                className="Facebook"
              >
                <Facebook />
                <br />
                Facebook
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
