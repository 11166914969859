import React from "react";
import { ReactComponent as Arrow } from "../images/arrow.svg";
import "./HeaderNavButton.css";

function HeaderNavButton( {className, id, isOpen, onClick} ) {

    return(
        <div className={`headerNav${isOpen ? " isOpen" : ""}${ className ? " " + className: "" }`}  id={id} >
            <button 
                aria-expanded={isOpen} 
                className="headerNav__button"
                onClick={ e => onClick(e)  }
            >
                EACADEMY
                <Arrow className="headerNav__arrow"/>
            </button>
        </div>
    )
}

HeaderNavButton.defaultProps = {
    className: "",
    id: "",
    children: null,
    onClick: null,
    isOpen: false,
}

export default HeaderNavButton;