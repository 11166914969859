interface AuditDetails {
  type: string;
  message: string;
  severity: "minor" | "moderate" | "major" | "critical";
  params: [];
}

interface AuditLog {
  details: AuditDetails;
  timestamp: Date;
}

export function auditLog(details: AuditDetails) {
  var log: AuditLog = {
    details,
    timestamp: new Date(),
  };

  console.log(log);
}
