import React, { useState, useRef, useEffect } from "react";
import "./HorizontalScrollArea.css"

function HorizontalScrollArea({ children, className, ...props}){
    const [ pos, setPos ] = useState(0);
    const [ buttonClasses, setButtonClasses ] = useState({ prev: "", next: ""});
    const scrollRef = useRef(null);
    
    useEffect( () => {
        if( scrollRef.current ){
            scrollRef.current.scrollLeft = pos;
        }
    }, [ pos, scrollRef ] )

    const MutatePos = ( by )=> {
        const clamp = ( min, val, max ) => Math.min( Math.max( val, min ), max );
        const expectedResult = pos + by;
        setPos(clamp(0,expectedResult, scrollRef.current.scrollWidth - scrollRef.current.clientWidth))
    }

    return(
        <div className={`hScrollArea ${ className } `}>
            <button 
                className={`hScrollArea__button hScrollArea__button-prev ${ pos === 0 ? "none" : "" } ${buttonClasses.prev}`}
                onClick={
                    () => {
                        setButtonClasses({ prev: "animate", next:buttonClasses.next })
                        if(scrollRef.current){
                            MutatePos( -scrollRef.current.clientWidth );
                            return true;
                        }
                        MutatePos( -150 );
                    }
                }
                onAnimationEnd={ ()=>{
                    setButtonClasses({ prev: "", next:buttonClasses.next })
                }}
            >
                <span className="screen-reader-text">previous</span>
            </button>
            <div ref={scrollRef}
                className={`hScrollArea__area`}
            >
                { children }
            </div>
            <button 
                className={`hScrollArea__button hScrollArea__button-next ${buttonClasses.next}`}
                onClick={() => {
                    setButtonClasses({ prev:buttonClasses.prev, next:"animate" })
                    if(scrollRef.current){
                        MutatePos( scrollRef.current.clientWidth );
                        return true;
                    }
                    MutatePos( 150 );
                } }
                onAnimationEnd={ ()=>{
                    setButtonClasses({ prev:buttonClasses.prev, next:"" })
                }}
            >
                <span className="screen-reader-text">previous</span>
            </button>
        </div>
    )
}


export default HorizontalScrollArea