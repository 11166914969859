import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { onError } from "../../libs/errorLib";
import VideoCard from "../../components/videocard/Videocard";
import Heading from "../../components/heading/Heading";
import CategoryPageLoader from "./CategoryPageLoader";
import { useAppContext } from "../../libs/contextLib";

import "./CategoryPage.css";

function CategoryPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [vids, setVids] = useState([]);
  const { id } = useParams();
  const categoryTitle = id ? id : "Videos";
  const { contentData } = useAppContext();

  useEffect(() => {
    async function fetchData() {
      try {
        const vids = contentData[id];
        setVids(vids);
        setIsLoading(false);
      } catch (e) {
        onError(e);
        setIsLoading(false);
      }
    }

    fetchData();
  }, [categoryTitle, contentData, id]);

  return (
    <>
      {isLoading ? (
        <CategoryPageLoader />
      ) : (
        <main className="categoryPage">
          <Heading level="h1" className="categoryPage__title">
            {categoryTitle
              .split("-")
              .map((word) => word[0].toUpperCase() + word.slice(1))
              .join(" ")}
          </Heading>
          <section className="categoryPage__videos">
            {vids.map((video, key) => (
              <VideoCard
                key={key}
                nid={video.id}
                imageSrc={video.thumbnail}
                title={video.title}
                duration={video.duration}
                rating={video.difficulty}
                alignwide
              ></VideoCard>
            ))}
          </section>
        </main>
      )}
    </>
  );
}

export default CategoryPage;
