import React, { useEffect, useState } from "react";
import { useInView } from 'react-intersection-observer';
import Heading from "../heading/Heading";
import VirtualScrollChild from "../virtualScrollChild/VirtualScrollChild";
import Videocard from "../videocard/Videocard";
import HorizontalScrollArea from "../horizontalScrollArea/HorizontalScrollArea";
import "./slider.css";
import LoadingClay from "../loadingClay/LoadingClay";

const MINCARDS = 3;

function Videoslider({ 
	title,
	items,
	isLoading,
 }) {
	const [lastRowRef, lastRowInView] = useInView();
	const [virtualItems, setVirtualItems] = useState([]);
	const [originalItems, setOriginalItems] = useState([]);

	useEffect(() => {
		const virtualChildProps = {
			width: "240px",
			height: "328px",
		}
		// if we dont consider this to be enough cards, duplicate
		const duplicateCardsUntilMinimum = ( cards ) => {
			if (cards.length < MINCARDS) {
				cards = cards.concat(cards);
			}
			if (cards.length < MINCARDS) {
				return duplicateCardsUntilMinimum(cards);
			}
			return cards;
		}

			// construct the cards from the props
			if( items.length > 0 ) {
				const innerCards = duplicateCardsUntilMinimum(items.map( 
					video => 
					<VirtualScrollChild {...virtualChildProps}>
						<Videocard 
							nid={video.id} 
							imageSrc={video.thumbnail} 
							category={title} 
							title={video.title} 
							duration={video.duration} 
							isPremium 
							rating={video.difficulty} />
					</VirtualScrollChild>))

				
				setOriginalItems(innerCards);
				setVirtualItems(innerCards);
			}
		}, [items, title] )

	// if last row is in view, call the last row handler
	useEffect(() => {
		const lastRowHandler = () => setVirtualItems( [].concat(virtualItems,originalItems) )
		lastRowInView && lastRowHandler();
	}, [ lastRowInView ] );

	// Map the elements by assigning the Ref to last index, unless there's only 1
	const Elements = virtualItems.map((listItem, i) => {
		const props = { key: i };
		( i === virtualItems.length - 1 &&
		  virtualItems.length -1 !== 0) && (props['ref'] = lastRowRef);
		return (
			<div {...props}>
				{ listItem }
			</div>
		);
	});
	
	const videoLoadingClay = <div>
		<LoadingClay width="208px" height="260px" className="loadingVideo"/>
		<LoadingClay />
		<LoadingClay height="14px" width="10rem" />
	</div>

	return (
		isLoading  ?
			<div className="videoslider" >
				<Heading level="h3" className="slidertitle" bold><LoadingClay colour="red" /></Heading>
				<div className={`videoslider__false-videos`} >
					{videoLoadingClay}
					{videoLoadingClay}
					{videoLoadingClay}
					{videoLoadingClay}
					{videoLoadingClay}
				</div>
			</div>
		:
			<div className="videoslider" >
				<Heading level="h3" className="slidertitle" bold>{title}</Heading>
				<HorizontalScrollArea className={`videoslider__videos`} >
					{ Elements }
				</HorizontalScrollArea>
			</div>
	);
}

Videoslider.defaultProps = {
	title: "Videos",
	items: [],
	isLoading: false,
}

export default Videoslider;
