/*
const config = {
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_evU6aPh7A",
        APP_CLIENT_ID: "26i3535h5b1ekbfrgp9i2f6ntg",
    },

    uri: {
        devapiuri: "https://localhost:5001",
        localiis: "http://locallfceacademy.api",
        stageapiuri: "https://eacademyapi.1cctech.net",
        lfcstageapiuri: "https://eacademy-api.lfcstage.com",
        lfcprodapiuri: "https://eacademy-api.liverpoolfc.com",

        getUri: (env) => {
            console.log(env);
            switch (env) {
                case "stage": return config.uri.stagecontentUri;
                case "lfcstage": return config.uri.lfcstageapiuri;
                case "prod": return config.uri.lfcprodapiuri;
                case "iis": return config.uri.localiis;
                case "dev":
                default: return config.uri.devapiuri;
            }
        }
    },

    stripe: {
        publickey: "pk_live_51Hi00oATsbzp1IzV4TdPj2r0qJFgzcWJjBDmtEtovu9QkyYt58gIuCJar402Hx54RrM8gQpmeb1cU0xY78WV8dHk00s21R9jXy",
        apiuri: () => config.uri.getUri("prod") + '/stripe'
    },

    dataUris: {
        contentMirrorUri: "http://ccdev.xyz/drupal/mirror.php",
        apiuri: () => config.uri.getUri("prod") + '/api/videodatas'
    },
    searchCookie: "lfc-recent-searches",
    searchCookieDelimiter: ',',
    maxmimumRecentSearches: 4,
    videoStaticKeyURL: "",
    requiresEmailValidation: false,
    monthlySubscriptionPrice: 4.99,
    annualSubscriptionPrice: 49.99,
};

export default config;
/!* stripe.env use only dev,stage,prod *!/

/!* our config
cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_0vLdxy3PE",
        APP_CLIENT_ID: "1lo5ie05eit23o14dgkd4ekiij",
    },

    lfc stage config
cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_OjZ5OA1K0",
        APP_CLIENT_ID: "6bq4789h4jkut4es3s1b7d0je9",
    },

    lfc prod
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_evU6aPh7A",
        APP_CLIENT_ID: "26i3535h5b1ekbfrgp9i2f6ntg",
    },

STRIPE:
pk_live_51Hi00oATsbzp1IzV4TdPj2r0qJFgzcWJjBDmtEtovu9QkyYt58gIuCJar402Hx54RrM8gQpmeb1cU0xY78WV8dHk00s21R9jXy
pk_test_51Hi00oATsbzp1IzVJr2fAGYJCfi7UUVi0cScnxJkC9F8Di42Pbyn7yITJmQsCUnOyOtspw3JdsVkU613P64nDLCM00MM8zsjwX

    *!/
*/
type SharedConfig = {
  searchCookie: string;
  searchCookieDelimiter: string;
  maximumRecentSearches: number;
  monthlySubscriptionPrice: number;
  annualSubscriptionPrice: number;
};
type EnvironmentConfig = {
  cognito: {
    region: string;
    userPoolId: string;
    appClientId: string;
  };
  apiUrl: string;
  stripe: {
    publicKey: string;
  };
  forgotPasswordUrl: string;
};
type Config = SharedConfig &
  EnvironmentConfig & {
    dataUrl: string;
    stripe: {
      apiUrl: string;
    };
  };

const devConfig: EnvironmentConfig = {
  cognito: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_f6k8YkAw1",
    appClientId: "2vf3063tcq3i55g3qnq2ua2vj4",
  },
  apiUrl: "https://localhost:5001",
  stripe: {
    publicKey:
      "pk_test_51Hi00oATsbzp1IzVJr2fAGYJCfi7UUVi0cScnxJkC9F8Di42Pbyn7yITJmQsCUnOyOtspw3JdsVkU613P64nDLCM00MM8zsjwX",
  },
  forgotPasswordUrl: "https://account.lfcstage.com/forgot-password",
};
const stageConfig: EnvironmentConfig = {
  cognito: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_f6k8YkAw1",
    appClientId: "2vf3063tcq3i55g3qnq2ua2vj4",
  },
  apiUrl: "https://eacademy-api.lfcstage.com",
  stripe: {
    publicKey:
      "pk_test_51Hi00oATsbzp1IzVJr2fAGYJCfi7UUVi0cScnxJkC9F8Di42Pbyn7yITJmQsCUnOyOtspw3JdsVkU613P64nDLCM00MM8zsjwX",
  },
  forgotPasswordUrl: "https://account.lfcstage.com/forgot-password",
};
const productionConfig: EnvironmentConfig = {
  cognito: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_v8rUoT834",
    appClientId: "5r8alilfgi38d04hofjs5hnu0a",
  },
  apiUrl: "https://eacademy-api.liverpoolfc.com",
  stripe: {
    publicKey:
      "pk_live_51Hi00oATsbzp1IzV4TdPj2r0qJFgzcWJjBDmtEtovu9QkyYt58gIuCJar402Hx54RrM8gQpmeb1cU0xY78WV8dHk00s21R9jXy",
  },
  forgotPasswordUrl: "https://account.liverpoolfc.com/forgot-password",
};

function getBaseConfig(): EnvironmentConfig {
  switch (window.location.host) {
    case "eacademy.liverpoolfc.com":
      return productionConfig;
    case "eacademy.lfcstage.com":
      return stageConfig;
    case "localhost:3000":
      return devConfig;
    default:
      return devConfig;
  }
}

export function config(): Config {
  const baseConfig = getBaseConfig();
  return {
    ...baseConfig,
    dataUrl: `${baseConfig.apiUrl}/api/videodatas`,
    stripe: {
      ...baseConfig.stripe,
      apiUrl: `${baseConfig.apiUrl}/stripe`,
    },
    searchCookie: "lfc-recent-searches",
    searchCookieDelimiter: ",",
    maximumRecentSearches: 4,
    monthlySubscriptionPrice: 4.99,
    annualSubscriptionPrice: 49.99,
  };
}
