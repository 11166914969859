import React from "react"
import { withToastProvider } from "../toast";

/* 
** Really simple wrapper so that we can have the toasts
** in the correct place (below the nav bar)
*/
function Main({ 
    children, 
    borderedPage = false, 
    ...props }){

    return( 
        <main {...props} className={`${borderedPage ? "borderedPage" : ""}`}>
            {children}
        </main>
    )
}

export default withToastProvider(Main)