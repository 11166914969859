export function onError(error) {
    console.log(error);
    let message = error.toString();

    if(error.status === 404){
        message = "Page Not Found";
    }
    if(error.status === 400){
        message = error.data.message;
    }

    // Auth errors
    if (!(error instanceof Error) && error.message) {
        message = error.message;
    }

    if (error.code === 'UsernameExistsException') {
        alert('We cannot create your account at this time, please check your credentials and try again');
    } else {
        alert('Something went wrong, please try again!');
    }
}
