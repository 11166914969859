import React from "react";
import "./HeaderNavFold.css";

function HeaderNavFold( {className, id, isOpen} ) {

    return(
        <div id={id} className={`headerNav__fold${isOpen ? " isOpen" : ""}${ className ? " " + className: "" }`} >
            <ul>
                <li><a href="https://liverpoolfc.com">Liverpoolfc.com</a></li>
                <li><a href="https://store.liverpoolfc.com">Store</a></li>
            </ul>
        </div>
    )
}

HeaderNavFold.defaultProps = {
    className: "",
    id: null,
}

export default HeaderNavFold;