import React from 'react';
import ReactDOM from 'react-dom';
import ScrollMemory from 'react-router-scroll-memory';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Amplify } from 'aws-amplify';
import { config } from './config';
import './index.css';
import * as Sentry from "@sentry/react";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config().cognito.region,
    userPoolId: config().cognito.userPoolId,
    userPoolWebClientId: config().cognito.appClientId
  },
});

Sentry.init({
  dsn: "https://001bdff33d52aeaadaaaac6d0d23553a@o4506150181273600.ingest.sentry.io/4506150590676992",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <BrowserRouter>
      <ScrollMemory />
      <App />
  </BrowserRouter>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
