import React from "react"
import Select from "../select/Select"
import "./LabeledSelect.css"

function LabeledSelect({
    children = null,
    className = null,
    id = "",
    labelText = "Select",
    onChange = null,
    autoFocus = false,
    ...props
}){

    return(
        <div className={`LabeledSelect${ className ? " " + className : "" }`}>
            <Select
                autoFocus={autoFocus}
                className={ `LabeledSelect__select` }
                id={ id }
                onChange={ onChange }
                { ...props } 
            >
                { children }
            </Select>
            <label htmlFor={ id } className={`LabeledSelect__label`}>{ labelText }</label>
        </div>
    )
}

export default LabeledSelect;