import React from "react"
import LoadingClay from "../loadingClay/LoadingClay"
import "./CategoryBubble.css"

function CategoryBubble({ children, className, id }){
    return (
        <div className={`categoryBubble${className ? " " + className : ""}`} id={id}>{ children }</div>
    )
}

CategoryBubble.defaultProps = {
    children: <LoadingClay height={"16px"} width={"3rem"} />,
    className: "",
    id: null,
}

export default CategoryBubble;