import React from "react";
import Poster from "../images/eAcadPlatformThumb1.png"
import "./ThumbnailCTA.css";

function ThumbnailCTA( { video,poster } ) {

    return(
<div className="videoWrapper">
<video className="video-container-fullwidth video-container-overlay" controls={true} autoPlay="" loop="" muted="" data-reactid=".0.1.0.0" poster={Poster}>
  <source type="video/mp4" data-reactid=".0.1.0.0.0" src={video}/>
</video>
</div>
    )
}

ThumbnailCTA.defaultProps = {

    video: "https://lfc-packaged-videos-2.s3.eu-west-2.amazonaws.com/home/video2.mp4",

}

export default ThumbnailCTA;
