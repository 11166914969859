import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { Button } from "../../components/button";
import { useMembershipDetails } from "hooks/useMembershipDetails";
import "./ThankYouPage.css";

function ThankYouPage() {
  const membershipDetails = useMembershipDetails();

  useEffect(() => {
    async function fetchPaymentResult() {
      await Auth.currentSession();
      const userinfo = await Auth.currentUserInfo();

      if (membershipDetails != null) {
        let dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "eec.purchase.new",
          ecommerce: {
            currencyCode: "gbp",
            purchase: {
              actionField: {
                id: userinfo.attributes.sub,
                affiliation: "eacademy",
              },
              products: [
                {
                  name: "eacademy",
                  id: "eAcademy subscription",
                  brand: "eacademy",
                  category: "eacademy",
                  variant: "eacademy",
                  quantity: 1,
                },
              ],
            },
          },
        });
      }
    }

    fetchPaymentResult();
  }, []);

  return (
    <main className="thankyou">
      <div className="thankyou__content">
        <h1 className=" Heading Heading__black Heading__bold"> Thank you.</h1>
        <h2 className=" Heading Heading__black Heading__bold">
          Welcome to eAcademy
        </h2>

        <p>
          You will shortly receive a receipt by e-mail. <br />
          You have 14 days to cancel your membership and receive a refund.
          Cancelling after this period will allow you accesss until your
          membership expires, your membership will not be auto-renewed.
        </p>

        <Button to="/" className="SignUp__button">
          Get Started
        </Button>
      </div>
    </main>
  );
}

export default ThankYouPage;
