import React, { useState, useEffect } from "react";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import Axios from "axios";
import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";
import Footer from "./components/footer/Footer";
import ToTop from "./components/toTop/ToTop";
import { config } from "./config";

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [contentData, setContentData] = useState(false);
  const [onloadTrigger, setOnloadTrigger] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Axios.get(`${config().dataUrl}`);
        const modifiedData = Object.fromEntries(
          Object.entries(data).map(([category, vidArray]) => [
            category,
            vidArray.map((vid) => ({ ...vid, category })),
          ])
        );
        setContentData(modifiedData);
      } catch (e) {
        onError(e);
      }
    };

    const checkAuth = async () => {
      try {
        await Auth.currentSession();
        userHasAuthenticated(true);
      } catch (e) {
        if (e !== "No current user") {
          alert("Something went wrong, please try again");
        }
      } finally {
        setIsAuthenticating(false);
      }
    };

    (async () => {
      await fetchData();
      await checkAuth();
    })();
  }, [onloadTrigger]);

  // async function handleLogout() {
  //   await Auth.signOut();
  //   userHasAuthenticated(false);
  //   history.push("/");
  // }

  return (
    !isAuthenticating && (
      <div className="App container">
        <AppContext.Provider
          value={{
            isAuthenticated,
            userHasAuthenticated,
            contentData,
            setOnloadTrigger,
          }}
        >
          <Routes />
        </AppContext.Provider>
        <Footer />
        <ToTop />
      </div>
    )
  );
}

export default App;
