import React, { useState } from "react";
import { useQuery } from "../../libs/hooksLib";
import { useAppContext } from "../../libs/contextLib";
import VideoCard from "../../components/videocard/Videocard";
import "./SearchPage.css";

const SearchPage = () => {
  let query = useQuery();
  const { contentData } = useAppContext();
  let queryString = query.get("q");

  const renderableResults = () => {
    const results = [];

    for (const category in contentData) {
      const videos = contentData[category];
      const categoryResults = videos.filter((data) => {
        const searchFields = `${data.title} ${data.body} ${data.category}`;
        return (
          searchFields.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
        );
      });
      results.push(...categoryResults);
    }

    if (results.length > 0) {
      return results.map((video, key) => (
        <VideoCard
          key={key}
          nid={video.id}
          imageSrc={video.thumbnail}
          title={video.title}
          duration={video.duration}
          rating={video.difficulty}
          alignwide
        ></VideoCard>
      ));
    }
    return "No results found...";
  };

  return (
    <div className="SearchPage">
      {queryString && (
        <p>
          <strong>Showing search results for: </strong>
          {`${queryString}...`}
        </p>
      )}
      <section className="SearchPage__videos">
        {queryString ? (
          renderableResults()
        ) : (
          <strong>Use the search button to enter a search term</strong>
        )}
      </section>
    </div>
  );
};

export default SearchPage;
