import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useParams } from "react-router-dom";
import Axios from "axios";
import ShakaPlayer from "shaka-player-react";
import { Button } from "../../components/button";
import Heading from "../../components/heading/Heading";
import StarRating from "../../components/starRating/StarRating";
import CategoryBubble from "../../components/categoryBubble/CategoryBubble";
import { config } from "../../config";
import VideoPageLoader from "./VideoPageLoader";
import { useMembershipDetails } from "hooks/useMembershipDetails";
import "material-design-icons/iconfont/material-icons.css";
import "shaka-player/dist/controls.css";
import "./VideoPage.css";

function VideoPage() {
  const { membershipData } = useMembershipDetails();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);

  const [video, setVideo] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      setIsLoadingUserData(true);

      const userinfo = await Auth.currentUserInfo();

      try {
        const { data } = await Axios.get(
          `${config().dataUrl}?lfcId=${userinfo.attributes['custom:myLfcId']}`
        );

        if (!data) return;

        const modifiedData = Object.fromEntries(
          Object.entries(data).map(([category, vidArray]) => [
            category,
            vidArray.map((vid) => ({ ...vid, category })),
          ])
        );

        var videos = [];
        for (const category in modifiedData) {
          const vids = modifiedData[category];

          vids.forEach((vid) => {
            if (vid.id.toString() === id) videos.push(vid);
          });
        }

        setVideo(videos[0]);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }

      setIsLoadingUserData(false);
    }

    fetchData();
  }, [id, video.length]);

  if (isLoading || video === null) {
    return <VideoPageLoader />;
  }

  return (
    <main className="videoPage">
      {!isLoadingUserData ? (
        !membershipData.hasActiveMembership ? (
          <div className="videoPage__unpaid">
            <img src={video.thumbnail} alt={video.title} />

            <div className="videoPage__unpaid-cta">
              <p>This content requires an active subscription.</p>

              <Button to="/payment" className="videoPage__unpaid-button">
                Subscribe Now
              </Button>
            </div>
          </div>
        ) : (
          <ShakaPlayer src={video.video} />
        ) //autoPlay={true} />
      ) : (
        <div />
      )}
      <div className="videoPage__content">
        <div className="videoMeta">
          <Heading level="h1" colour={"red"}>
            {video.title}
          </Heading>

          <CategoryBubble>{video.category}</CategoryBubble>

          <span className="duration">{video.duration}</span>

          <StarRating rating={video.difficulty} />
        </div>

        <div className="videoDescription">
          <p dangerouslySetInnerHTML={{ __html: video.body }} />
        </div>
      </div>
    </main>
  );
}

export default VideoPage;
