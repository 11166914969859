import React from 'react';
import { useInView } from "react-intersection-observer";

/**
 * A wrapper component for children of a
 * virtual scroll. Computes inline style and
 * handles whether to display props.children.
 */
function VirtualScrollChild({height, width, children}) {
    const [ref, inView] = useInView({
        rootMargin: '100px 100px',
      });
    const style = {
        width,
        height,
        overflow: 'hidden'
    };
    return (
        <div style={style} ref={ref}>
            {inView ? children : null}
        </div>
    );
}

export default VirtualScrollChild;