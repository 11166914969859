import React, { useState } from "react"
import LabeledInput from "../labeledInput/LabeledInput"
import { LoaderButton } from "../button";
import Heading from "../heading/Heading"
import { onError } from "../../libs/errorLib";
import { useAppContext } from "../../libs/contextLib";
import { useHistory } from "react-router-dom"
import { Auth } from "aws-amplify";

function ConfirmationForm({
    username = "",
    password = "",
    paymentUrl = "/payment"
}){
    const [ confirmationCode, setConfirmationCode ] = useState("")
    const [ isLoading, setIsLoading ] = useState(false)
    const { userHasAuthenticated } = useAppContext();
    const history = useHistory();

    function validateConfirmationForm() {
        return confirmationCode.length > 0;
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        try {
            await Auth.confirmSignUp(username, confirmationCode.trim());
            await Auth.signIn(username, password);
            history.push(paymentUrl)
            userHasAuthenticated(true);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }

    }

    return (
        <form onSubmit={ async ( e ) => await handleConfirmationSubmit( e ) }>
            <Heading level="h1">Confirm account</Heading>
            <LabeledInput
                labelText="Confirmation Code"
                autoFocus
                type="tel"
                onChange={ ( e ) => setConfirmationCode( e.target.value ) }
                value={ confirmationCode }
            ></LabeledInput>
            <p>Please check your email for the code.</p>
            <br />
            <LoaderButton
                block
                type="submit"
                isLoading={isLoading}
                disabled={!validateConfirmationForm()}
            >
                Verify
            </LoaderButton>
        </form>
    )
}

export default ConfirmationForm;