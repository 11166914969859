import React from "react"
import "./LoadingSpinner.css"

function LoadingSpinner( { className, style= null } ){
    return(
        <div style={style} className={`LoadingSpinner${ className ? " " + className : ""}`}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default LoadingSpinner;