import React, { useState, useRef } from 'react'
import "./LabeledInput.css"
export default ({
    autoComplete = null,
    autoFocus = false,
    className,
    disabled = false,
    id = null,
    labelText ="",
    name,
    failedValidation = false,
    type = "text",
    value,
    required = false,
    min = null,
    max = null,
    regexLimiter = null,
    ...props
    }) => {

    const [isEmpty, setIsEmpty] = useState( value ? false : true )
    const inputRef = useRef(null);


    return( 
        <div className={`labeledInput ${ className || "" }`} id={ id } >
            <input 
                className={`labeledInput__input ${ !isEmpty ? "active" : ""}`}
                autoComplete={ autoComplete }
                autoFocus={ autoFocus }
                disabled={ disabled }
                min = { min }
                max = { max }
                id={ name || "bigInput" }
                name={ name || "bigInput" } 
                ref={ inputRef }
                type={ type } 
                value={ value }
                required={ required }
                onKeyDown = { (e) => {
                    if(props.onKeyDown)
                    {
                        props.onKeyDown(e);
                    }
                }}
                onChange={ ( e )=> { 
                    if(inputRef.current.value.length > 0){
                        setIsEmpty(false) 
                    }
                    else{
                        setIsEmpty(true) 
                    }

                    if (regexLimiter != null) {
                        inputRef.current.value = inputRef.current.value.replace(regexLimiter, '')
                    }

                    if(props.onChange) {
                        props.onChange(e);
                    }
                }}
            ></input>
            <label 
                htmlFor={name || "bigInput"} 
                className={`labeledInput__label ${ !isEmpty ? "active" : ""} ${ failedValidation ? "invalid" : ""}`}>
                { labelText }
            </label>
        </div>
    )
  }

