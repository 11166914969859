import React from "react"
import { Link } from "react-router-dom"
import { config } from "../../config"
import "./RecentSearch.css"

const MAXSEARCHES = config().maximumRecentSearches;

function RecentSearch( {
        isOpen,
        searches = [],
    }){

    let classNames = ["RecentSearch"]
    if( isOpen && searches.length > 0) {
        classNames.push("isOpen");
    }

    if (searches.length > MAXSEARCHES) {
        searches.splice(0, searches.length - MAXSEARCHES)
    }

    return (
        <div className={ classNames.join(" ") }>
            <p className="RecentSearch__title">Recent Searches</p>
            <ul className="RecentSearch__list">
                { searches.map( ( s, i ) =>
                    <li key={i}>
                        <Link to={`/search?q=${ s.split(' ').join('+') }`}>{s}</Link>
                    </li> ) }
            </ul>
        </div>
    )
}

export default RecentSearch;
