import "./BlockCTA.css";
import { Link } from "react-router-dom";

function BlockCTA({ href }) {
  return (
    <div className="blockCTA">
      <h3 className="title">
        eAcademy is the official online skills platform of LFC.
      </h3>
      <p className="blockCTA_content">
        To access, join an LFC International Academy coaching program or attend
        a UK Soccer School.
      </p>
      <p className="blockCTA_content">
        For more information on our global programs, click
        <a href={href} className="blockCTA_content">
          <strong>&nbsp;here.</strong>
        </a>
      </p>
      <p className="blockCTA_content">
        Click
        <Link to="/my-account">
          <strong>&nbsp;here&nbsp;</strong>
        </Link>
        to redeem your access code
      </p>
    </div>
  );
}

BlockCTA.defaultProps = {
  href: "/",
};

export default BlockCTA;
