import React from "react"
import './Heading.css';

function Heading ({ className, id, children, level, colour, bold, uppercase }) {
    const validHeadingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
    const validColours = ['red', 'white', 'black'];

    const safeHeading = level ? level.toLowerCase() : '';
    const safeColour = colour ? colour.toLowerCase() : '';
    const SelectedHeading = validHeadingLevels.includes(safeHeading) ? safeHeading : 'p';
    const SelectedColour = validColours.includes(safeColour) ? safeColour : 'red';

    let classes = [
        className,
        `Heading`,
        `Heading__${SelectedColour}`,
    ];
    if( uppercase ){
        classes.push(`Heading__uppercase`);
    }
    if( bold ){
        classes.push(`Heading__bold`);
    }

    return(
        <SelectedHeading id={id} className={ classes.join(" ") }>
            {children}
        </SelectedHeading>
    )

}

Heading.defaultProps = {
    colour : `red`,
    className : ``,
    id : null,
    level : ``,
    bold: false,
    uppercase: false,
}

export default Heading;