import React, { useEffect, useRef } from 'react';
import "./Toast.css"

function Toast({ children, remove }) {
  const removeRef = useRef();
  removeRef.current = remove;

  useEffect(() => {
    const duration = 5000;
    const id = setTimeout(() => removeRef.current(), duration);
    return () => clearTimeout(id);
  }, []);

  return (
    <div className="toast">
      <div className="toast__text">
        { children }
      </div>
      <div>
        <button onClick={remove} className="toast__closeBtn">
            <p className="screen-reader-text">Close notification</p>
            <span></span>
            <span></span>
        </button>
      </div>
    </div>
  );
}

export default Toast;